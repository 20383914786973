import React from 'react';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import Content from '../components/Content';

const NotFoundPage = () => {
  return (
    <Layout>
      <HelmetDatoCms title="404, Page Not Found | BGA" />
      <main>
        <Banner title="404, Page Not Found" />
        <Content content="<p>Sorry this page was not found and might not exist, please check the URL.</p>" />
      </main>
    </Layout>
  );
};

export default NotFoundPage;
