import React from 'react';
import styled from 'styled-components';
import { sectionMargins } from '../utilities';
import { Container, HTMLContent } from './ui';

const StyledContent = styled.section`
  ${sectionMargins()};
`;

const Content = ({ content }) => {
  return (
    <StyledContent>
      <Container>
        <HTMLContent content={content} />
      </Container>
    </StyledContent>
  );
};

export default Content;
